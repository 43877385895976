import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layout'
import SEO from '../components/SEO/SEO'
import { Link } from 'gatsby'
import { Section, Container } from 'sqrl-design-system'

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title='Demo' />
        <Section>
          <Container>
            <h1>Page Demos</h1>
            <hr />
            <Link to="/" title="Home Page" style={{ display: 'block' }}>
              Home Page (Consumer App)
            </Link>
            <Link
              to="/workforce/"
              title="Workforce"
              style={{ display: 'block' }}
            >
              Workforce
            </Link>
            <Link to="/signup" title="Sign Up" style={{ display: 'block' }}>
              Sign Up
            </Link>
            <Link to="/login" title="Login" style={{ display: 'block' }}>
              Login
            </Link>
            <Link
              to="/forgot-password"
              title="Forgot Password"
              style={{ display: 'block' }}
            >
              Forgot Password
            </Link>
            <Link
              to="/set-password"
              title="Set Password"
              style={{ display: 'block' }}
            >
              Set Password
            </Link>
            <Link
              to="/verify-account"
              title="Verify Account"
              style={{ display: 'block' }}
            >
              Verify Account
            </Link>
            <Link
              to="/identification-documents-received"
              title="Identification Documents Received"
              style={{ display: 'block' }}
            >
              Identification Documents Received
            </Link>
            <Link
              to="/identity-verified"
              title="Identity Verified"
              style={{ display: 'block' }}
            >
              Identity Verified
            </Link>
            <Link
              to="/identification-failed"
              title="Identity Verified Failed"
              style={{ display: 'block' }}
            >
              Identity Verified Failed
            </Link>
            <Link
              to="/change-password"
              title="Change Password"
              style={{ display: 'block' }}
            >
              Change Password
            </Link>
            <Link
              to="/wellness-welcome"
              title="Wellness Welcome"
              style={{ display: 'block' }}
            >
              Wellness Welcome
            </Link>

            <Link
              to="/terms-of-use/"
              title="Terms of Use"
              style={{ display: 'block' }}
            >
              Terms of Use
            </Link>
            <Link
              to="/privacy-policy/"
              title="Privacy Policy"
              style={{ display: 'block' }}
            >
              Privacy Policy
            </Link>
            <Link
              to="/password-updated/"
              title="Password Updated"
              style={{ display: 'block' }}
            >
              Password Updated Screen
            </Link>
            <Link
              to="/contact-us/"
              title="Contact Us"
              style={{ display: 'block' }}
            >
              Contact Us
            </Link>
            <Link
              to="/faqs/"
              title="FAQs"
              style={{ display: 'block' }}
            >
              FAQs
            </Link>
            <Link
              to="/on-boarding-business-type/"
              title="On-Boarding Business Type"
              style={{ display: 'block' }}
            >
              On-Boarding Step 1: Business Type
            </Link>
            <Link
              to="/on-boarding-c-corp-personal-verification/"
              title="On-Boarding Personal Verification"
              style={{ display: 'block' }}
            >
              On-Boarding Step 2a: Personal Verification (C-Corp)
            </Link>
            <Link
              to="/on-boarding-sole-prop-personal-verification/"
              title="On-Boarding Personal Verification"
              style={{ display: 'block' }}
            >
              On-Boarding Step 2b: Personal Verification (Prop)
            </Link>
            <Link
              to="/on-boarding-sole-prop-business-verification/"
              title="On-Boarding Business Verification"
              style={{ display: 'block' }}
            >
              On-Boarding Step 3b: Business Verification (Prop)
            </Link>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default Index

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [fields___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`
